@use 'colours.scss' as colours;

@import '~katex/dist/katex.css';

/* You can add global styles to this file, and also import other style files */
body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  overflow: hidden;
  font-family: Arial;
  background-color: #fff;
  line-height: 1.2em;
}

h2 {
  line-height: 1.2em;
}

option.default {
  color: #555;
}

.old-grade {
  img {
    &.handCursor {
      display: none;
    }
  }
  td {
    padding: 5px;
    padding-top: 10px;
  }
  .section {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  .subSection {
    margin-top: 10px;
  }
  a[title] {
    color: colours.$blue;
    text-decoration: underline;
    cursor: help;
    &:hover {
      color: colours.$blue2;
    }
  }
}
.title {
  font-size: 1.4em;
  font-weight: bold;
}
.subtitle {
  font-size: 1.2em;
  font-weight: bold;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// material
.mat-form-field-wrapper {
  margin: 0;
}
mat-dialog-container {
  max-width: 500px;
}

button[mat-raised-button] {
  background-color: colours.$blue2;
  color: #fff;
}

a {
  color: colours.$blue;

  &:hover {
    color: colours.$blue2;
  }
}

.mat-tooltip {
  font-size: 14px;
}

img {
  max-width: 100%;
}

sup {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
  padding-left: 1px;
}
.footnote {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 30px;
  sup {
    min-width: 25px;
    width: 25px;
  }
  span {
    flex: 1 1 1px;
  }
  font-size: 0.9em;
}

li {
  padding: 4px 0;
}

@media screen and (max-width: 440px) {
  .footnote {
    margin: 0 10px;
    sup {
      min-width: 10px;
      width: 10px;
    }
  }
  $sup-left-margin: 15px;
  body {
    font-size: 0.6em;
  }

  ul,
  ol {
    padding-left: 10px;
  }

  mat-icon {
    font-size: 15px !important;
    width: 15px !important;
    height: 15px !important;
  }

  .mat-raised-button {
    font-size: 10px !important;
    padding: 0 7px !important;
    margin: 0 !important;
    line-height: 20px !important;
    margin: 5px 0 !important;
  }
}

.removed {
  background-color: #e4b5b5;
  p,
  ul,
  li,
  div {
    background-color: inherit;
  }
}
.added {
  background-color: #b8f3b8;
  p,
  ul,
  li,
  div {
    background-color: inherit;
  }
}
